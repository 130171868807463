import store from '@/app/store';
import { initClient } from './client';
import { BookingRequest, PriceRequest, QuoteRequest } from './types';
import { addDays, format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { Reservations, Service } from '@/global';

const { authClient, client } = initClient();

type LoginParams = {
  email: string;
  password: string;
};

export const login = async (params: LoginParams) => {
  const response = await client.post('/auth/login', params);

  if (response.status !== 200) {
    throw new Error('Invalid credentials');
  }

  localStorage.setItem('accessToken', response.data.data.access_token);
  localStorage.setItem('refreshToken', response.data.data.refresh_token);
  localStorage.setItem('timeout', response.data.data.expires);
};

export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('timeout');
  store.setState((s) => ({ ...s, isAuthenticated: false, user: undefined }));
};

export const getUser = async () => {
  const response = await authClient.get('/users/me');
  return response.data;
};

export const isAuthenticated = async () => {
  try {
    return await getUser();
  } catch (error) {
    return false;
  }
};

export async function getSellableServices() {
  const response = await authClient.get<Service[]>(
    '/booking-endpoints/services'
  );
  return response.data;
}

export async function getSellableService(id?: string) {
  if (!id) {
    return null;
  }
  const response = await authClient.get(`/booking-endpoints/services/${id}`);
  return response.data;
}

export async function getReservations({
  limit = -1,
  filters,
}: {
  limit?: number;
  filters?: {
    bookingDateRange?: DateRange;
    bookedDateRange?: DateRange;
    service: string;
    customer: string;
    status?: 'true' | 'false' | 'all';
    ticket?: string;
    shift?: string;
  };
} = {}) {
  let filtersString = '';
  let index = 0;
  if (filters?.bookedDateRange?.from) {
    filtersString += `&filter[_and][0][_and][${index}][booked_date][_gte]=${format(
      filters.bookedDateRange.from,
      'yyyy-MM-dd'
    )}`;
    index++;
  }
  if (filters?.bookedDateRange?.to) {
    filtersString += `&filter[_and][0][_and][${index}][booked_date][_lte]=${format(
      filters.bookedDateRange.to,
      'yyyy-MM-dd'
    )}`;
    index++;
  }
  if (filters?.bookingDateRange?.from) {
    filtersString += `&filter[_and][0][_and][${index}][booking_date][_gt]=${format(
      filters.bookingDateRange.from,
      'yyyy-MM-dd'
    )}`;
    index++;
  }
  if (filters?.bookingDateRange?.to) {
    //we add a day to the to date to include the whole day
    filtersString += `&filter[_and][0][_and][${index}][booking_date][_lt]=${format(
      addDays(filters.bookingDateRange.to, 1),
      'yyyy-MM-dd'
    )}`;
    index++;
  }
  if (filters?.service && filters.service !== 'all') {
    filtersString += `&filter[_and][0][_and][${index}][service][_eq]=${filters.service}`;
    index++;
  }
  if (filters?.status && filters.status !== 'all') {
    filtersString += `&filter[_and][0][_and][${index}][status][_eq]=${filters.status}`;
    index++;
  }
  if (filters?.customer) {
    filtersString += `&filter[_and][0][_and][${index}][customer_name][_icontains]=${filters.customer}`;
    index++;
  }
  if (filters?.ticket && filters.ticket !== '') {
    filtersString += `&filter[_and][0][_and][${index}][id][_eq]=${filters.ticket}`;
    index++;
  }
  if (filters?.shift && filters.shift !== 'all') {
    filtersString += `&filter[_and][0][_and][${index}][shift][_eq]=${filters.shift}`;
    index++;
  }

  const response = await authClient.get<{ data: Reservations[] }>(
    `/items/reservations?fields[]=*&fields[]=service.name&fields[]=variants.variant.*&fields[]=variants.amount&fields[]=seller.first_name&fields[]=seller.last_name&fields=*.*&limit=${limit}&sort[]=-booking_date${filtersString}`
  );
  return response.data?.data;
}

export async function getReservation({ id }: { id?: string }) {
  if (!id) {
    return;
  }
  console.log('fetch reservation');
  const response = await authClient.get<{ data: Reservations }>(
    `/items/reservations/${id}?fields[]=*&fields[]=service.name&fields[]=service.supplier.*&fields[]=variants.variant.name&fields[]=variants.variant.sort&fields[]=seller.first_name&fields[]=seller.last_name&fields[]=service.company_name&fields=*.*&limit=1&sort[]=-booking_date`
  );
  console.log(response);
  return response.data?.data;
}

export async function requestQuote(quoteRequest: QuoteRequest) {
  const q = {
    ...quoteRequest,
    seller: store.getState().user.data.id,
    date: format(quoteRequest.date, 'yyyy-MM-dd'),
  };
  const response = await authClient.post(
    `/booking-endpoints/services/${quoteRequest.service}/quote`,
    q
  );
  return response.data;
}

export async function book(bookingRequest: BookingRequest) {
  const q = {
    ...bookingRequest,
    seller: store.getState().user.data.id,
    date: format(bookingRequest.date, 'yyyy-MM-dd'),
  };
  const response = await authClient.post(
    `/booking-endpoints/services/${bookingRequest.service}/book`,
    q
  );
  return response.data;
}

export async function reservationVoid(id: string): Promise<{
  reservationId: string;
  status: string;
}> {
  const response = await authClient.post(
    `/booking-endpoints/reservations/${id}/void`
  );
  return response.data;
}

export async function getPrices({ service, date, shift }: PriceRequest) {
  const q = {
    service,
    date: format(date, 'yyyy-MM-dd'),
    shift,
    seller: store.getState().user.data.id,
  };
  const response = await authClient.post(
    `/booking-endpoints/services/${service}/prices`,
    q
  );
  return response.data;
}
