import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getRandomElementFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function formatDate(
  date: string | Date = new Date(),
  formatStr = 'PPP'
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, formatStr, { locale: it });
}

export function formatDateTime(
  date: string | Date = new Date(),
  formatStr = 'PPPpp'
): string {
  return format(date, formatStr, { locale: it });
}

export function getPropertyValueOrReference<T, K extends keyof T>(
  obj: T | string,
  key: K,
  fallback?: string
): string | T[K] {
  return typeof obj === 'string'
    ? fallback
      ? fallback
      : obj
    : String(obj[key]);
}
