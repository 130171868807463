export const FormRow = ({
  children,
  title,
  subtitle,
}: {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
}) => {
  return (
    <div className="flex flex-col lg:flex-row gap-2 w-full gap-4">
      <div className="lg:w-1/4 flex-shrink-0">
        {title && (
          <h2 className="text-base/7 font-semibold text-gray-900">{title}</h2>
        )}
        {subtitle && <p className="text-sm/4 text-gray-600">{subtitle}</p>}
      </div>
      {children && <div className="lg:w-3/4">{children}</div>}
    </div>
  );
};
