import { requestQuote } from '@/lib/api';
import { useApiSend } from '@/lib/api/client';
import { QuoteRequest, QuoteResponse } from '@/lib/api/types';
import { useEffect, useState } from 'react';

/**
 * This hook is used to fetch a quote for a given quote request.
 * It uses the `requestQuote` API function to retrieve the quote.
 */
export function useRequestQuote(quoteRequest: QuoteRequest) {
  const [quoteResponse, setQuoteResponse] = useState<QuoteResponse>();
  const { isPending, mutate } = useApiSend<QuoteResponse, QuoteRequest>(
    (data) => {
      return requestQuote(data);
    },
    {}
  );

  useEffect(() => {
    mutate(quoteRequest, {
      onSuccess: (data, a, f) => {
        setQuoteResponse(data);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  }, [quoteRequest, mutate]);

  return {
    isPending,
    quoteResponse,
  };
}
