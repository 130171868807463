import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import './instrument';
import App from './app/app';
import * as Sentry from '@sentry/react';
import { ErrorPage } from './app/pages/error';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  // {
  //   // Callback called when an error is thrown and not caught by an ErrorBoundary.
  //   onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
  //     console.warn('Uncaught error', error, errorInfo.componentStack);
  //   }),
  //   onCaughtError: Sentry.reactErrorHandler(),
  //   onRecoverableError: Sentry.reactErrorHandler(),
  // }
);

root.render(
  <StrictMode>
    {/* <Sentry.ErrorBoundary fallback={ErrorPage} showDialog> */}
    <App />
    {/* </Sentry.ErrorBoundary> */}
  </StrictMode>
);
