import { book } from '@/lib/api';
import { useApiSend } from '@/lib/api/client';
import { BookingRequest, BookingResponse } from '@/lib/api/types';

/**
 * This hook is used to book a service.
 * It uses the `book` API function to retrieve the quote.
 */
export function useBook() {
  const { isPending, mutate } = useApiSend<BookingResponse, BookingRequest>(
    (data) => {
      return book(data);
    },
    {}
  );

  return {
    isPending,
    mutate,
  };
}
