import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface NoDataAlertProps {
  title?: string;
  description?: string;
  className?: string;
}

export function NoDataAlert({
  title,
  description,
  className,
}: NoDataAlertProps) {
  const { t } = useTranslation();

  return (
    <div className={cn('text-center', className)}>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {title || t('common.no_data.title')}
      </h3>
      {(description || t('common.no_data.description')) && (
        <p className="mt-1 text-sm text-gray-500">
          {description || t('common.no_data.description')}
        </p>
      )}
    </div>
  );
}
