import { format } from 'date-fns';
import { useState } from 'react';
import VariantRow from './variant-row';
import { QuoteRequest } from '@/lib/api/types';
import store from '@/app/store';
import ModalOrDrawer from '../modal-or-drawer';
import CalendarModal from './calendar-modal';
import CustomerForm from './customer-form';
import Loading from '../loading';
import { usePrices } from './use-prices';
import { useRequestQuote } from './use-request-quote';
import { useBook } from './use-book';
import { Service } from '@/global';
import { Separator } from '@/ui/separator';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '@/ui/error-alert';
import { FormRow } from '@/ui/form-row';
import { useNavigate } from 'react-router-dom';
import { errorMapping } from './utils';
import { PriceTotals } from './prices-totals';

interface Props {
  service: Service;
}

export default function BookingForm({ service }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    user: { data: user },
  } = store((s) => s);

  const [quoteRequest, setQuoteRequest] = useState<QuoteRequest>({
    service: service.id,
    date: new Date(),
    shift: service?.shifts[0]?.id,
    seller: user.id,
    variants: service.variants.reduce((a, v) => ({ ...a, [v.id]: 0 }), {}),
  });

  const { prices } = usePrices(quoteRequest);
  const { isPending: isQuoting, quoteResponse } = useRequestQuote(quoteRequest);
  const { isPending: isBooking, mutate: book } = useBook();

  return (
    <div className="space-y-4 w-full p-4">
      {!isQuoting && !!errorMapping(quoteRequest, quoteResponse) && (
        <ErrorAlert
          title={t(
            `components.booking-form.errors.${errorMapping(
              quoteRequest,
              quoteResponse
            )}.title`
          )}
          description={t(
            `components.booking-form.errors.${errorMapping(
              quoteRequest,
              quoteResponse
            )}.description`
          )}
        />
      )}

      {isQuoting || (isBooking && <Loading />)}

      {!isBooking && (
        <div className="w-full space-y-6 py-2 md:py-6 md:space-y-10">
          <FormRow
            title={t('components.booking-form.departure-date.title')}
            subtitle={t('components.booking-form.departure-date.description')}
          >
            <ModalOrDrawer
              disabled={isQuoting}
              trigger={
                <div className="inline-block">
                  <CalendarTrigger
                    date={quoteRequest.date}
                    shiftName={
                      service.shifts?.find((s) => s.id === quoteRequest.shift)
                        ?.name ?? t('components.booking-form.select-date-time')
                    }
                    shiftTime={
                      service.shifts?.find((s) => s.id === quoteRequest.shift)
                        ?.from ?? ''
                    }
                  />
                </div>
              }
            >
              {(setOpen) => (
                <CalendarModal
                  selectedDate={quoteRequest.date}
                  selectedShift={quoteRequest.shift}
                  shifts={service.shifts}
                  onSelected={(date, shift, close) => {
                    setQuoteRequest({ ...quoteRequest, date, shift });
                    close && setOpen(false);
                  }}
                />
              )}
            </ModalOrDrawer>
          </FormRow>

          <FormRow
            title={t('components.booking-form.variants.title')}
            subtitle={t('components.booking-form.variants.description')}
          >
            <div className="gap-3 grid grid-cols-1 xl:grid-cols-2">
              {service.variants?.map((variant) => (
                <VariantRow
                  key={variant.id}
                  price={prices?.prices?.[variant.id]?.total ?? 0}
                  notSellable={
                    quoteResponse?.prices?.[variant.id]?.type === 'error' ||
                    prices?.prices?.[variant.id]?.type === 'error'
                  }
                  variant={variant}
                  isLoading={isQuoting}
                  amount={quoteRequest.variants[variant.id]}
                  onSetValue={(value) => {
                    setQuoteRequest({
                      ...quoteRequest,
                      variants: {
                        ...quoteRequest.variants,
                        [variant.id]: value,
                      },
                    });
                  }}
                />
              ))}
            </div>
          </FormRow>

          {!isQuoting &&
            !isBooking &&
            prices &&
            !errorMapping(quoteRequest, quoteResponse) && (
              <FormRow
                title={t('components.booking-form.totals.title')}
                subtitle={t('components.booking-form.totals.description')}
              >
                <PriceTotals prices={prices} quoteRequest={quoteRequest} />
              </FormRow>
            )}

          {!isQuoting &&
            !isBooking &&
            !errorMapping(quoteRequest, quoteResponse) && (
              <FormRow
                title={t('components.booking-form.customer.title')}
                subtitle={t('components.booking-form.customer.description')}
              >
                <CustomerForm
                  onSubmit={(data) => {
                    book(
                      {
                        ...quoteRequest,
                        seller: store.getState().user.data.id,
                        date: format(quoteRequest.date, 'yyyy-MM-dd'),
                        customerName: data.name,
                        customerEmail: data.email,
                        customerPhone: data.phone,
                      },
                      {
                        onSuccess(result) {
                          navigate(`/reservations/${result.id}`);
                        },
                      }
                    );
                  }}
                />
              </FormRow>
            )}
        </div>
      )}
    </div>
  );
}

const CalendarTrigger = ({
  date,
  shiftName,
  shiftTime,
  disabled,
}: {
  date: Date;
  shiftName: string;
  shiftTime: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      className="items-center w-full rounded-lg border bg-white p-2 px-6 flex shadow-sm space-x-5"
    >
      <div className="aspect-square  flex flex-col justify-center items-center">
        <div className="text-3xl font-semibold leading-none">
          {format(date, 'd')}
        </div>
        <div className="text-sm text-slate-600 uppercase">
          {format(date, 'MMM')}
        </div>
      </div>
      <Separator orientation="vertical" className="h-16" />

      <span className="flex text-left">
        <span className="flex flex-col text-sm">
          <span className="font-medium text-slate-900">{shiftName}</span>
          <span className="text-slate-700 flex items-center space-x-1">
            <ClockIcon className="size-4 mr-1 text-slate-700" />
            {shiftTime}
          </span>
        </span>
      </span>
    </button>
  );
};
