import { getSellableServices } from '@/lib/api';
import { useApiGet } from '@/lib/api/client';
import { Service } from '@/lib/api/types';
import { Heading } from '@/ui/heading';
import { useTranslation } from 'react-i18next';
import { SkeletonLoader } from '@/ui/skeleton-loader';
import { ASSETS_URL } from '@/consts';
import { Link } from 'react-router-dom';

import { ErrorAlert } from '@/ui/error-alert';
import { NoDataAlert } from '@/ui/no-data-alert';

export default function BookableServices() {
  const { t } = useTranslation();
  const { data, isLoading, isError, isLoadingError, isFetching, refetch } =
    useApiGet<Service[]>(
      ['bookable-services'],
      async () => await getSellableServices(),
      {
        enabled: true,
        refetchOnWindowFocus: true,
        retry: 1,
      }
    );

  return (
    <div>
      <Heading
        className="p-4"
        title={t('components.bookable-services.title')}
        description={t('components.bookable-services.description')}
      />

      {(isLoading || isFetching) && <SkeletonLoader className="p-4" />}

      {(isError || isLoadingError) && (
        <ErrorAlert
          onRetry={refetch}
          title={t('common.errors.unexpected.description')}
          hideDescription
        />
      )}

      {!data?.length &&
        !isLoading &&
        !isFetching &&
        !isError &&
        !isLoadingError && <NoDataAlert />}

      <ul className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-4 w-full p-4">
        {data?.map((service) => (
          <BookableServiceCard key={service.id} service={service} />
        ))}
      </ul>
    </div>
  );
}

const BookableServiceCard = ({ service }: { service: Service }) => {
  return (
    <Link className="group" to={`/book/${service.id}`}>
      <div className="bg-slate-100 flex-grow-0 flex-shrink-0 sm:w-auto aspect-[4/2] sm:aspect-[4/6] w-full rounded-lg overflow-hidden">
        <img
          alt={service.name.toLowerCase()}
          src={ASSETS_URL + service?.cover?.id}
          className="bg-slate-100 aspect-[4/2] sm:aspect-[4/6] w-full rounded-lg object-cover hover:scale-110 transition-all duration-300"
          onError={(e) => (e.currentTarget.src = `/no-data.png`)}
        />
      </div>
      <div className="p-2">
        <h3 className="text-sm/5 font-bold text-gray-900 capitalize">
          {service.name.toLowerCase()}
        </h3>

        <p className="line-clamp-3 text-xs/5 text-gray-500 capitalize">
          {service.subtitle}
        </p>
      </div>
    </Link>
  );
};
