import { PhoneInput } from '@/components/phone-input';
import { CustomerInfo } from '@/lib/api/types';
import { Button } from '@/ui/button';
import { Input } from '@/ui/input';
import { Label } from '@/ui/label';
import { Formik } from 'formik';
import { CalendarCheck } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface CustomerFormProps {
  onSubmit: (values: CustomerInfo) => void;
}

export function CustomerForm({ onSubmit }: CustomerFormProps) {
  const { t } = useTranslation();
  return (
    <Formik<CustomerInfo>
      initialValues={{
        name: '',
        email: '',
        phone: '',
      }}
      onSubmit={(values) => {
        onSubmit(values as CustomerInfo);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="space-y-2">
          <div>
            <Label htmlFor="email">
              {t('components.booking-form.customer.name')}
            </Label>
            <Input
              id="name"
              type="name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Paolo Rossi"
              required
            />
            {/* {errors.email && touched.email && errors.email} */}
          </div>
          <div className="">
            <Label htmlFor="email">
              {t('components.booking-form.customer.email')}
            </Label>
            <Input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="me@example.com"
              required
            />

            {/* {errors.email && touched.email && errors.email} */}
          </div>
          <div className="">
            <Label htmlFor="phone">
              {t('components.booking-form.customer.phone')}
            </Label>
            <PhoneInput
              defaultCountry="IT"
              name="phone"
              value={values.phone}
              onChange={(value) => handleChange('phone')(value)}
              onBlur={handleBlur}
              required
            />
            {/* {errors.email && touched.email && errors.email} */}
          </div>

          <div className="pt-6">
            <Button type="submit" disabled={isSubmitting} size={'lg'}>
              <CalendarCheck className="mr-2 size-4" />
              {t('components.booking-form.book-now')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CustomerForm;
