import { DrawerContent, DrawerTrigger, Drawer } from '@/ui/drawer';
import { ReactNode, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@/ui/dialog';
import { useMediaQuery } from '@uidotdev/usehooks';

export function ModalOrDrawer({
  children,
  trigger,
  disabled,
}: {
  trigger: ReactNode;
  children: (setOpen: (open: boolean) => void) => ReactNode;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');

  return isSmallDevice ? (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger disabled={disabled} asChild>
        {trigger}
      </DrawerTrigger>
      <DrawerContent>{children(setOpen)}</DrawerContent>
    </Drawer>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger disabled={disabled} asChild>
        {trigger}
      </DialogTrigger>
      <DialogTitle></DialogTitle>
      <DialogContent aria-describedby="content" className="p-0">
        {children(setOpen)}
      </DialogContent>
    </Dialog>
  );
}

export default ModalOrDrawer;
