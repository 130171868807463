import { Reservations, VariantReserved } from '@/global';

const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds
export function calculateDeposit(reservation: Reservations) {
  return reservation.variants.reduce(
    (acc, variant) => acc + Number(variant.deposit) * variant.amount,
    0
  );
}

export function calculateBalance(reservation: Reservations) {
  return reservation.variants.reduce(
    (acc, variant) => acc + Number(variant.balance) * variant.amount,
    0
  );
}

export function calculateFee(reservation: Reservations) {
  return reservation.variants.reduce(
    (acc, variant) => acc + Number(variant.fee) * variant.amount,
    0
  );
}

export function calculateValue(reservation: Reservations) {
  return reservation.variants.reduce(
    (acc, variant) => acc + Number(variant.value) * variant.amount,
    0
  );
}

export function calculateTotal(reservation: Reservations) {
  return calculateFee(reservation) + calculateValue(reservation);
}

export function isRecentSellerReservation(
  reservation: Reservations,
  userId: string
) {
  const reservationDate = new Date(reservation.date_created);
  const now = new Date();
  const timeDiff = now.getTime() - reservationDate.getTime();

  console.log(timeDiff, TEN_MINUTES, reservation.seller.id, userId);
  return (
    timeDiff < TEN_MINUTES &&
    reservation.seller.id === userId &&
    reservation.status !== 'false'
  );
}

export function sortedVariantsByReservation(reservation: Reservations) {
  return sortedVariantsReserved(reservation?.variants ?? []);
}

export function sortedVariantsReserved(variants: VariantReserved[]) {
  return variants.sort((a, b) => {
    const variantA = typeof a.variant === 'string' ? 0 : a.variant.sort ?? 0;
    const variantB = typeof b.variant === 'string' ? 0 : b.variant.sort ?? 0;
    return variantA - variantB;
  });
}

export function sortRecord<R>(record: R & { sort?: number | null }[]) {
  return record.sort((a, b) => {
    return (a.sort ?? 0) - (b.sort ?? 0);
  });
}

export function getAllVariants(reservations: Reservations[]) {
  const services = new Map();

  // Feading
  for (const reservation of reservations) {
    if (!services.has(reservation.service.id)) {
      services.set(reservation.service.id, []);
    }

    for (const variant of reservation.variants) {
      services.get(reservation.service.id)?.push({
        ...variant.variant,
        service: reservation.service,
      });
    }
  }

  for (const k of services.keys()) {
    const service = services.get(k);
    const uniqueVariants = new Map();
    for (const variant of service) {
      uniqueVariants.set(variant.id, variant);
    }
    services.set(k, sortRecord(Array.from(uniqueVariants.values())));
  }

  // Remove duplicates and sortß
  return Array.from(services.values()).flat();
}

export function sumVariantById(
  reservations: Reservations[],
  variantId: string
): number {
  return reservations.reduce((total, reservation) => {
    const variant = reservation.variants.find(
      (v) => v.variant.id === variantId
    );
    return total + (variant ? variant.amount : 0);
  }, 0);
}

export function calculateTotalDeposit(reservations: Reservations[]): number {
  return reservations.reduce((total, reservation) => {
    return total + calculateDeposit(reservation);
  }, 0);
}

export function calculateTotalBalance(reservations: Reservations[]): number {
  return reservations.reduce((total, reservation) => {
    return total + calculateBalance(reservation);
  }, 0);
}

export function calculateTotalFee(reservations: Reservations[]): number {
  return reservations.reduce((total, reservation) => {
    return total + calculateFee(reservation);
  }, 0);
}

export function calculateTotalValue(reservations: Reservations[]): number {
  return reservations.reduce((total, reservation) => {
    return total + calculateValue(reservation);
  }, 0);
}

export function calculateTotalAmount(reservations: Reservations[]): number {
  return reservations.reduce((total, reservation) => {
    return total + calculateTotal(reservation);
  }, 0);
}

export function groupReservationsByDate(
  reservations: Reservations[]
): Record<string, Record<string, number>> {
  const grouped: Record<string, Record<string, number>> = {};

  reservations.forEach((reservation) => {
    const dateKey = reservation.booking_date.split('T')[0]; // Assuming reservation.date is a Date object
    if (!grouped[dateKey]) {
      grouped[dateKey] = {};
    }

    reservation.variants.forEach((variant) => {
      const variantId = `${variant.variant.id}`;
      if (!grouped[dateKey][variantId]) {
        grouped[dateKey][variantId] = 0;
      }
      grouped[dateKey][variantId] += variant.amount; // Assuming amount is a number
    });
  });

  return grouped;
}
