import { Shift } from '@/global';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

export default function CalendarShifts({
  shifts,
  selectedShift,
  onSelected,
}: {
  shifts: Shift[];
  selectedShift: string;
  onSelected: (shift: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="w-full space-y-4 p-3 h-full relative">
      {shifts.map((shift) => (
        <button
          key={shift.id}
          aria-label={shift.name ?? undefined}
          className={cn(
            'w-full text-left relative block bg-white p-2 px-4 flex shadow-sm'
          )}
          onClick={() => onSelected(shift.id)}
        >
          <span className="flex items-center">
            <span className="flex flex-col text-sm">
              <span className="font-medium text-gray-900 text-xl capitalize">
                {shift.name}
              </span>
              <span className="text-gray-500">
                <span className="block sm:inline">
                  {t('components.booking-form.departure-time.from', {
                    time: shift.from,
                  })}
                </span>
                <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <span className="block sm:inline">
                  {t('components.booking-form.departure-time.to', {
                    time: shift.to,
                  })}
                </span>
              </span>
            </span>
          </span>
          <span
            className="pointer-events-none absolute -inset-px rounded-lg border-2"
            aria-hidden="true"
          ></span>
          <svg
            className={cn('size-5 text-slate-600 absolute top-2 right-2', {
              hidden: selectedShift !== shift.id,
            })}
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      ))}
    </div>
  );
}
