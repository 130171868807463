import Markdown from 'react-markdown';
import { cn } from '@/lib/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/ui/select';
import Money from '../../ui/money';
import { Variant } from '@/global';
import { NotAvailableStamp } from '@/ui/not-available-stamp';

interface Props {
  variant: Variant;
  amount: number;
  price: number;
  notSellable?: boolean;
  onSetValue: (p: number) => void;
  isLoading: boolean;
}

const VariantRow = ({
  variant,
  price,
  amount,
  notSellable,
  onSetValue,
  isLoading,
}: Props) => {
  return (
    <div
      className={cn(
        'border rounded shadow-sm mb-1 relative overflow-hidden',
        notSellable ? 'text-foreground/10' : ''
      )}
    >
      {notSellable && <NotAvailableStamp />}

      <div className="w-full p-4 flex gap-2 items-center ">
        <div className="flex items-center text-xl w-full text-3xl font-semibold leading-none">
          {variant.name}
        </div>
        <div className="">
          <Select
            onValueChange={(value) => onSetValue(Number(value))}
            value={amount.toString()}
            disabled={isLoading || notSellable}
          >
            <SelectTrigger className="text-lg">
              <SelectValue>{amount}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              {[...Array(21)].map((_, index) => (
                <SelectItem key={index} value={index.toString()}>
                  {index}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>&times;</div>
        <div className="text-lg text-right">
          <Money amount={price}></Money>
        </div>
      </div>

      <Markdown className="text-sm p-3 bg-slate-100 h-full rounded-t">
        {variant.description}
      </Markdown>
    </div>
  );
};

export default VariantRow;
