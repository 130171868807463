import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { motion } from 'framer-motion';
import { useMeasure } from '@uidotdev/usehooks';
import CalendarShifts from './calendar-shifts';
import { calAnimation, shiftAnimation, styles } from './calendar-configuration';
import { Shift } from '@/global';
import { Heading } from '@/ui/heading';
import { useTranslation } from 'react-i18next';

const STEPS = ['CALENDAR', 'SHIFT'];

export type CalendarProps = {
  onSelected: (date: Date, shift: string, close?: boolean) => void;
  shifts: Shift[];
  selectedDate: Date;
  selectedShift: string;
};

export default function CalendarModal({
  selectedDate,
  selectedShift,
  shifts,
  onSelected,
}: CalendarProps) {
  const { t } = useTranslation();
  const [step, setStep] = useState<(typeof STEPS)[number]>(STEPS[0]);
  const [ref, { width = 100, height = 100 }] = useMeasure();
  const divSize = {
    width: width ?? 'auto',
    minWidth: width ?? 'auto',
    height: height ?? 'auto',
  };

  return (
    <motion.div
      className="flex overflow-hidden items-center relative"
      ref={ref}
    >
      <AnimatePresence mode="popLayout">
        {step === STEPS[0] ? (
          <motion.div
            className="w-full space-y-4"
            {...calAnimation(width ?? 0)}
            style={divSize}
          >
            <Heading
              className="p-4"
              title={t('components.booking-form.departure-day.title')}
              description={t(
                'components.booking-form.departure-day.description'
              )}
            />
            <div className="p-4">
              <DayPicker
                initialFocus
                mode="default"
                defaultMonth={selectedDate}
                selected={selectedDate}
                onDayClick={(date: Date) => {
                  onSelected(
                    date,
                    selectedShift,
                    // close if there is only one shift
                    shifts.length > 1 ? false : true
                  );
                  setStep(STEPS[1]);
                }}
                disabled={{ before: new Date() }}
                showOutsideDays
                classNames={styles}
                numberOfMonths={1}
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence mode="popLayout">
        {step === STEPS[1] ? (
          <motion.div
            className="w-full"
            {...shiftAnimation(width ?? 0)}
            style={divSize}
          >
            <Heading
              className="p-4"
              title={t('components.booking-form.departure-time.title')}
              description={t(
                'components.booking-form.departure-time.description'
              )}
            />
            <CalendarShifts
              shifts={shifts}
              selectedShift={selectedShift}
              onSelected={(shift) => {
                onSelected(selectedDate, shift, true);
              }}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
}
