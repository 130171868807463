import { useParams } from 'react-router-dom';
import { getReservation } from '@/lib/api';
import { useApiGet } from '@/lib/api/client';
import Loading from '@/components/loading';
import { useEffect } from 'react';
import '../print.css';
import { ASSETS_URL } from '@/consts';
import Markdown from 'react-markdown';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import {
  calculateBalance,
  calculateDeposit,
  calculateTotal,
  sortedVariantsByReservation,
} from '@/lib/reservation';
import {
  formatDate,
  formatDateTime,
  getPropertyValueOrReference,
} from '@/lib/utils';

function Ticket() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useApiGet(
    [],
    async () => await getReservation({ id }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  const handlePrint = () => {
    // window.print();
  };

  useEffect(() => {
    if (isLoading || isFetching || !data) {
      return;
    }
    handlePrint();
  }, [data, isLoading, isFetching]);

  return !isLoading && !isFetching && data ? (
    <div className="grid grid-cols-5 h-full print">
      <div className="max-w-md col-span-2 w-full h-full mx-auto bg-slate-100">
        <div className="flex flex-col">
          <div className="bg-white relative rounded p-4 m-4">
            <div className="flex items-center justify-between">
              <div className="flex">
                <h2 className="font-bold">{t('ticket.title')}</h2>
              </div>
              <div className="ml-auto text-blue-800 font-mono text-sm">
                {data.id.slice(0, 6)}
              </div>
            </div>
            <div className="border-b border-dashed border-b-2 my-3"></div>
            <div className="flex  p-2">
              <div className="flex flex-col space-y-1">
                <div className="w-full flex-none text-lg text-blue-800 font-bold leading-none">
                  {data.service.name}
                </div>
                <div className="text-xs font-bold text-slate-600">
                  {data.service.company_name}
                </div>
              </div>
              <div className="flex flex-col mx-auto">
                <img
                  alt={data.service.company_name}
                  src={`${ASSETS_URL}${data.service.logo}`}
                  className="w-20 p-1"
                />
              </div>
            </div>
            <div className="border-b border-dashed border-b-2  pt-5">
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -left-2"></div>
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -right-2"></div>
            </div>
            <div className="flex items-center mb-5 p-2 text-sm">
              <div className="flex flex-col">
                <span className="text-xs font-bold text-slate-600">
                  {t('ticket.service-date')}
                </span>
                <div className="font-mono">{formatDate(data.booked_date)}</div>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-xs font-bold text-slate-600">
                  {t('ticket.shift')}
                </span>
                <div className="font-mono">{data.shift.from}</div>
              </div>
            </div>
            <div className="flex items-center px-2 w-full space-x-2">
              {sortedVariantsByReservation(data)?.map((variant) => (
                <div key={variant.id} className="flex flex-col text-sm">
                  <span className="text-xs font-bold text-slate-600">
                    {getPropertyValueOrReference(variant.variant, 'name')}
                  </span>
                  <div className="font-mono text-center">{variant.amount}</div>
                </div>
              ))}
            </div>
            <div className="border-b border-dashed border-b-2 my-2 pt-5">
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -left-2"></div>
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -right-2"></div>
            </div>
            <div className="px-2 pt-3 text-sm space-y-5">
              <div className="flex flex-col">
                <span className="text-xs font-bold text-slate-600">
                  {t('ticket.passenger')}
                </span>
                <div className="font-mono">{data.customer_name}</div>
                <div className="font-mono">{data.customer_phone}</div>
                <div className="font-mono">{data.customer_email}</div>
              </div>
            </div>
            <div className="border-b border-dashed border-b-2 my-2 pt-5">
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -left-2"></div>
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -right-2"></div>
            </div>
            <div className="px-2 pt-3 text-sm space-y-5">
              <div className="flex flex-col">
                <span className="text-xs font-bold text-slate-600">
                  {t('ticket.supplier')}
                </span>
                <div className="font-mono break-all">
                  <p>{data.service.supplier.company}</p>
                  <p>{data.service.supplier.address}</p>
                  <p>{data.service.supplier.email}</p>
                  <p>{data.service.supplier.phone_number}</p>
                </div>
              </div>
            </div>
            {data.service.supplier.id !== data.seller.id ? (
              <>
                <div className="border-b border-dashed border-b-2 my-2 pt-5">
                  <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -left-2"></div>
                  <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -right-2"></div>
                </div>
                <div className="px-2 pt-3 text-sm space-y-5">
                  <div className="flex flex-col">
                    <span className="text-xs font-bold text-slate-600">
                      {t('ticket.seller')}
                    </span>
                    <div className="font-mono break-all">
                      <p>{data.seller.company}</p>
                      <p>{data.seller.address}</p>
                      <p>{data.seller.email}</p>
                      <p>{data.seller.phone_number}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="border-b border-dashed border-b-2 my-2 pt-5">
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -left-2"></div>
              <div className="absolute rounded-full w-5 h-5 bg-slate-100 -mt-2 -right-2"></div>
            </div>
            <div className="px-2 pt-3 text-sm space-y-5">
              <div className="flex flex-col">
                <span className="text-xs font-bold text-slate-600">
                  {t('ticket.seller')}
                </span>
                <div className="font-mono break-all">
                  <p>
                    {data.seller.first_name} {data.seller.last_name} <br />
                    {formatDateTime(new Date(data.booking_date))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <QRCode
            size={256}
            className="w-32"
            value={data.id}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className="col-span-3 prose text-xs">
        <div className="bg-slate-50 p-4">
          <div className="flex justify-between items-center">
            <div>
              <div className="text-xs font-bold text-slate-600">
                {t('ticket.deposit')}
              </div>
              <div className=" font-mono ">{calculateDeposit(data)} &euro;</div>
            </div>
            <div>
              <div className="text-xs font-bold text-slate-600">
                {t('ticket.balance')}
              </div>
              <div className="font-mono">
                {calculateBalance(data)}
                &euro;
              </div>
            </div>
            <div>
              <div className="text-xs font-bold text-slate-600">
                {t('ticket.total')}
              </div>
              <div className="font-mono">{calculateTotal(data)} &euro;</div>
            </div>
          </div>
        </div>
        <div className="px-4 space-y-2">
          <Markdown>{data.service.description}</Markdown>
          <Markdown>{data.service.memo}</Markdown>
          <Markdown>{data.service.contract}</Markdown>
          <div className="flex items-center gap-x-4">
            {data.service.links.map((l) => (
              <div className="flex flex-col items-center justify-center">
                <QRCode
                  size={256}
                  className="w-24 h-24"
                  value={l.link}
                  viewBox={`0 0 256 256`}
                />
                <div>{l.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {data.status === 'false' && <VoidOverlay />}
    </div>
  ) : (
    <Loading />
  );
}
export default Ticket;

const VoidOverlay = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute inset-0 flex items-center justify-center z-20!">
      <div className="transform rotate-45 text-6xl font-black text-red-500 border-[1rem] border-red-500 p-8">
        {t('ticket.voided')}
      </div>
    </div>
  );
};
