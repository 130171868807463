import * as React from 'react';
import { Button } from '../../ui/button';
import { useApiGet } from '@/lib/api/client';
import { getReservations, getSellableServices } from '@/lib/api';
import Layout from './layout';
import { Form } from 'react-router-dom';
import DataGrid from '@/components/report/grid';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/ui/select';
import { Label } from '@/ui/label';
import { Input } from '@/ui/input';
import { DatePickerWithRange } from '@/ui/daterange-picker';
import { Field, Formik } from 'formik';
import { DateRange } from 'react-day-picker';
import { Switch } from '@/ui/switch';
import { Tabs } from '@/ui/tabs';
import { TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { Chart } from '@/components/report/chart';
import { Card, CardContent, CardHeader } from '@/ui/card';
import Loading from '@/components/loading';
import { Service } from '@/global';
import { Preset, PresetList } from '@/components/report/preset-list';
import { Heading } from '@/ui/heading';
import { Table2 } from 'lucide-react';
import { ResetIcon } from '@radix-ui/react-icons';
import { useDownloadCsv } from '@/components/report/useCsv';
import List from '@/components/report/list';
import { useTranslation } from 'react-i18next';

export type Resevation = {
  customer_name: string;
  customer_phone: string;
};

export type Filters = {
  bookingDateRange?: DateRange;
  bookedDateRange?: DateRange;
  service: string;
  ticket: string;
  customer: string;
  status?: 'true' | 'false' | 'all';
  shift: string;
};

const initialFilters: Filters = {
  customer: '',
  ticket: '',
  service: 'all',
  bookingDateRange: undefined,
  bookedDateRange: undefined,
  status: undefined,
  shift: 'all',
};
export default function ReservationsPage() {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState<Filters>(initialFilters);
  const [selectedPreset, setSelectedPreset] = React.useState<{
    parent: string;
    preset: string;
  } | null>(null);
  const [showMoney, setShowMoney] = React.useState(false);
  const [showVariants, setShowVariants] = React.useState(false);
  const downloadCsv = useDownloadCsv();

  const { data: sellableServices } = useApiGet<Service[]>(
    ['service-list'],
    async () => await getSellableServices(),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      retry: 1,
    }
  );

  const {
    data = [],
    isLoading,
    error,
    isError,
    isLoadingError,
    isFetching,
    refetch,
  } = useApiGet(
    ['reservations', JSON.stringify(filters)],
    async () => await getReservations({ filters }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  function applyPreset(preset: Preset) {
    setFilters(preset.filters);
    setShowVariants(preset.columns.variants);
    setShowMoney(preset.columns.money);
  }

  return (
    <Layout
      side={
        <>
          <Heading
            title={t('reservations.search.title')}
            description={t('reservations.search.description')}
            className="p-4"
            side={
              <Button
                variant="outline"
                onClick={() => setFilters(initialFilters)}
              >
                <ResetIcon className="mr-1" />
                {t('reservations.reset')}
              </Button>
            }
          ></Heading>
          <Tabs defaultValue="filters" className="print:hidden w-full p-4">
            <TabsList
              className="flex space-x-4 mb-4 w-full"
              defaultValue="filters"
            >
              <TabsTrigger
                value="filters"
                className="w-full data-[state=active]:bg-slate-100 rounded-md px-3 py-2 text-sm font-medium text-slate-500 hover:text-slate-700"
              >
                {t('reservations.filters')}
              </TabsTrigger>
              <TabsTrigger
                value="columns"
                className="w-full data-[state=active]:bg-slate-100 rounded-md px-3 py-2 text-sm font-medium text-slate-500 hover:text-slate-700"
              >
                {t('reservations.columns')}
              </TabsTrigger>
              <TabsTrigger
                value="presets"
                className="w-full data-[state=active]:bg-slate-100 rounded-md px-3 py-2 text-sm font-medium text-slate-500 hover:text-slate-700"
              >
                {t('reservations.presets')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="filters">
              <Formik<Filters>
                initialValues={filters}
                enableReinitialize
                onSubmit={(values) => {
                  setFilters(values);
                  setSelectedPreset(null);
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                    <div className="space-y-1">
                      <Label>{t('reservations.ticket')}</Label>
                      <Field name="ticket" as={Input} />
                    </div>
                    <div className="space-y-1">
                      <Label>{t('reservations.customer')}</Label>
                      <Field name="customer" as={Input} />
                    </div>
                    {sellableServices && (
                      <div className="space-y-1">
                        <Label>{t('reservations.service')}</Label>
                        <Select
                          value={values.service}
                          onValueChange={(value) => {
                            setFieldValue('shift', 'all');
                            setFieldValue('service', value);
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="All" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="all">
                              {t('reservations.all')}
                            </SelectItem>
                            {sellableServices?.map((service) => (
                              <SelectItem key={service.id} value={service.id}>
                                {service.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                    {values.service !== 'all' && (
                      <div className="space-y-1">
                        <Label>{t('reservations.shift')}</Label>
                        <Select
                          value={values.shift}
                          onValueChange={(value) =>
                            setFieldValue('shift', value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="All" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="all">
                              {t('reservations.all')}
                            </SelectItem>
                            {sellableServices
                              ?.find((s) => s.id === values.service)
                              ?.shifts.map((shift) => (
                                <SelectItem
                                  key={`SHIFT-${shift.id}`}
                                  value={shift.id}
                                >
                                  {shift.name}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                    <div className="space-y-1">
                      <Label>{t('reservations.booking-date')}</Label>
                      <DatePickerWithRange
                        value={values.bookingDateRange}
                        onChange={(range) =>
                          setFieldValue('bookingDateRange', range)
                        }
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>{t('reservations.booked-date')}</Label>
                      <DatePickerWithRange
                        value={values.bookedDateRange}
                        onChange={(range) =>
                          setFieldValue('bookedDateRange', range)
                        }
                      />
                    </div>
                    <div className="space-y-1">
                      <Label>{t('reservations.status')}</Label>

                      <Select
                        value={values.status}
                        onValueChange={(value) =>
                          setFieldValue('status', value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="All" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">
                            {t('reservations.all')}
                          </SelectItem>
                          <SelectItem key={'true'} value={'true'}>
                            {t('reservations.active')}
                          </SelectItem>
                          <SelectItem key={'false'} value={'false'}>
                            {t('reservations.void')}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>

                    <Button type="submit">Search</Button>
                  </Form>
                )}
              </Formik>
            </TabsContent>
            <TabsContent value="columns">
              <section>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="show-money"
                      checked={showMoney}
                      onCheckedChange={setShowMoney}
                    />
                    <Label htmlFor="show-money">
                      {t('reservations.show-money')}
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="show-variants"
                      checked={showVariants}
                      onCheckedChange={setShowVariants}
                    />
                    <Label htmlFor="show-variants">
                      {t('reservations.show-variants')}
                    </Label>
                  </div>
                </div>
              </section>
            </TabsContent>
            <TabsContent value="presets">
              <PresetList
                services={sellableServices ?? []}
                applyPreset={applyPreset}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
              />
            </TabsContent>
          </Tabs>
        </>
      }
      main={
        <div className="flex-grow  print:space-y-0 w-full">
          {isFetching || isLoading ? (
            <Loading />
          ) : (
            <>
              <Heading
                className="p-4 md:sticky md:top-[64px] bg-white z-10"
                title={t('reservations.title', { count: data.length })}
                description={t('reservations.description')}
                side={
                  <Button
                    onClick={() => {
                      downloadCsv(data);
                    }}
                    variant="outline"
                  >
                    <Table2 className="w-4 h-4 mr-2" />
                    {t('reservations.download-csv')}
                  </Button>
                }
              />

              <List
                reservations={data}
                showMoney={showMoney}
                showVariants={showVariants}
              />
            </>
          )}
        </div>
      }
    />
  );
}
