import { QuoteRequest, QuoteResponse } from '@/lib/api/types';

export function errorMapping(
  quoteRequest: QuoteRequest,
  quoteResponse?: QuoteResponse
): 'no-prices' | 'no-availability' | 'no-request' | undefined {
  if (quoteResponse?.code === 'availability_error') {
    return 'no-availability';
  } else if (quoteResponse?.code === 'price_error') {
    return 'no-prices';
  } else if (getTotalRequestedAmount(quoteRequest) === 0) {
    return 'no-request';
  }
  return;
}
export function getTotalRequestedAmount(quoteRequest: QuoteRequest) {
  return Object.values(quoteRequest.variants).reduce(
    (a, v: QuoteRequest['variants'][string]) => a + v,
    0
  );
}
