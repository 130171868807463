import { cn } from '@/lib/utils';
import { Skeleton } from './skeleton';

export function SkeletonLoader({ className }: { className?: string }) {
  return (
    <div className={cn('space-y-2', className)}>
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  );
}
