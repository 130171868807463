type MoneyProps = {
  amount?: number;
};

export function Money({
  amount,
  children,
}: React.PropsWithChildren<MoneyProps>) {
  return (
    <span className="opacity-80">
      {new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount || Number(children) || 0)}
    </span>
  );
}

export default Money;
