import { cn } from '@/lib/utils';
import { Button } from './button';

interface HeadingProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  side?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
}

export function Heading({
  title,
  description,
  side,
  icon,
  className,
}: HeadingProps) {
  return (
    <div
      className={cn('border-b border-gray-200 pb-5 print:hidden', className)}
    >
      <div className="flex justify-between items-start">
        <div className="flex gap-3 items-center">
          {icon && <div className="shrink-0">{icon}</div>}
          <div>
            <h3 className="text-2xl font-semibold leading-6 text-gray-900 ">
              {title}
            </h3>
            {description && (
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                {description}
              </p>
            )}
          </div>
        </div>
        {side}
      </div>
    </div>
  );
}
