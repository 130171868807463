import { PriceResponse, QuoteRequest } from '@/lib/api/types';
import { useTranslation } from 'react-i18next';
import { getTotalRequestedAmount } from './utils';

export function PriceTotals({
  quoteRequest,
  prices,
}: {
  quoteRequest: QuoteRequest;
  prices: PriceResponse;
}) {
  const { t } = useTranslation();
  return (
    <div className="rounded-lg border p-4 space-y-2">
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium">
          {t('components.booking-form.totals.selected-items')}
        </span>
        <span className="text-sm">{getTotalRequestedAmount(quoteRequest)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium">
          {t('components.booking-form.totals.total-amount')}
        </span>
        <span className="text-sm font-semibold">
          €{' '}
          {Object.entries(quoteRequest.variants)
            .reduce((total, [variantId, quantity]) => {
              return (
                total + (prices?.prices?.[variantId]?.total ?? 0) * quantity
              );
            }, 0)
            .toFixed(2)}
        </span>
      </div>
    </div>
  );
}
