import * as React from 'react';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  hideDescription?: boolean;
  onRetry?: () => void;
}

export function ErrorAlert({
  className,
  title,
  description,
  hideDescription,
  onRetry,
  ...props
}: AlertProps) {
  const { t } = useTranslation();
  return (
    <div className={cn('rounded-md bg-red-50 p-4', className)} {...props}>
      <div className="flex">
        <div className="shrink-0">
          <svg
            className="size-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3 flex justify-between w-full items-center space-x-2">
          <div>
            <h3 className="text-sm font-medium text-red-800">
              {title || t('common.errors.unexpected.title')}
            </h3>

            {!hideDescription ? (
              <div className="text-sm text-red-700">
                <p>
                  {description || t('common.errors.unexpected.description')}
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex items-start">
            <div className="-mx-2 -my-1.5 flex">
              {onRetry && (
                <button
                  type="button"
                  onClick={onRetry}
                  className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                >
                  {t('common.actions.retry')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
