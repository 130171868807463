import { Reservations } from '@/global';
import {
  calculateBalance,
  calculateDeposit,
  calculateFee,
  calculateTotal,
  calculateTotalAmount,
  calculateTotalBalance,
  calculateTotalDeposit,
  calculateTotalFee,
  calculateTotalValue,
  calculateValue,
  getAllVariants,
  sumVariantById,
} from '@/lib/reservation';
import { formatDate, formatDateTime } from '@/lib/utils';
import { Circle } from '@/ui/divider';
import Money from '@/ui/money';
import { StatusBadge } from '@/ui/status-badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate-inside/es';

export default function List({
  reservations,
  showMoney = true,
  showVariants = true,
}: {
  reservations: Reservations[];
  showMoney?: boolean;
  showVariants?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <ul className="divide-y divide-gray-100 w-full">
        {reservations.map((reservation) => (
          <li key={reservation.id}>
            <Link
              to={`/reservations/${reservation.id}`}
              className="relative flex items-center hover:bg-slate-50 flex py-5 px-4 w-full justify-between space-x-3 md:space-x-3"
            >
              <div className="w-full space-y-3">
                <div className="flex w-full space-x-3 items-center">
                  <div className="flex flex-col flex-grow sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center sm:space-x-3 md:space-x-0 lg:space-x-3">
                    <div className="text-xs/6 text-gray-900 font-mono flex">
                      {reservation.id.slice(0, 8)}
                    </div>
                    <div>
                      <p className="text-sm/5 font-bold text-gray-900">
                        {reservation.service.name}
                      </p>
                      <p className="text-sm/6 text-gray-900 capitalize">
                        {reservation.customer_name.toLowerCase()}
                      </p>
                      <p className="text-xs/5 text-gray-500">
                        {formatDate(reservation.booked_date)}{' '}
                        <Circle className="inline" /> {reservation.shift.name}
                      </p>
                    </div>
                  </div>
                  <div className="hidden xl:block">
                    <ReservationAmounts
                      reservation={reservation}
                      showVariants={showVariants}
                      showMoney={showMoney}
                    />
                  </div>
                  <div>
                    <div className="flex-0">
                      <p className="text-sm/6 text-gray-900 capitalize">
                        {reservation.seller.first_name}{' '}
                        {reservation.seller.last_name}
                      </p>
                      <div className="text-xs/5 text-gray-500">
                        {formatDateTime(reservation.booking_date, 'Pp')}
                      </div>
                    </div>

                    <div className="flex  flex-0">
                      {reservation.status === 'true' && (
                        <StatusBadge
                          color="emerald"
                          label={t('common.reservation.active')}
                        />
                      )}
                      {reservation.status === 'false' && (
                        <StatusBadge
                          color="red"
                          label={t('common.reservation.void')}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="block md:block xl:hidden ">
                  <ReservationAmounts
                    reservation={reservation}
                    showVariants={showVariants}
                    showMoney={showMoney}
                  />
                </div>
              </div>
              <svg
                className="size-5 flex-none text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </Link>
          </li>
        ))}
      </ul>
      <div className="sticky bottom-0 left-0 right-0 p-4 bg-slate-50 backdrop-blur-sm border-t border-gray-100">
        <div className="flex justify-between w-full gap-3 flex-col">
          <ReservationsTotals
            reservations={reservations}
            showVariants={showVariants}
            showMoney={showMoney}
          />
        </div>
      </div>
    </>
  );
}

function Table({ children }: { children: React.ReactNode }) {
  return (
    <table className="text-xs text-gray-500 w-full flex-0 text-center self-end">
      {children}
    </table>
  );
}
function Th({
  content,
  truncate = true,
}: {
  content: string;
  truncate?: boolean;
}) {
  return (
    <th className="p-1 border rounded-lg min-w-[70px]">
      {truncate ? (
        <Tooltip>
          <TooltipTrigger>
            <Truncate text={content} offset={4} width={70} />
          </TooltipTrigger>
          <TooltipContent>{content}</TooltipContent>
        </Tooltip>
      ) : (
        content
      )}
    </th>
  );
}

function Td({
  content,
  isMoney,
}: {
  content: string | number;
  isMoney?: boolean;
}) {
  return (
    <td className="p-1 border rounded-lg min-w-[70px] font-mono">
      {isMoney ? <Money amount={Number(content)} /> : content}
    </td>
  );
}

function ReservationAmounts({
  reservation,
  showVariants = true,
  showMoney = true,
}: {
  reservation: Reservations;
  showVariants?: boolean;
  showMoney?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1">
      {showVariants ? (
        <Table>
          <tr>
            {reservation.variants.map((variant) => (
              <Th key={variant.id} content={variant.variant.name ?? ''} />
            ))}
          </tr>
          <tr>
            {reservation.variants.map((variant) => (
              <Td content={variant.amount.toString()} />
            ))}
          </tr>
        </Table>
      ) : null}

      {showMoney ? (
        <table className="text-xs text-gray-500 w-full flex-0 text-center">
          <tr>
            <Th content={t('reservation.totals.deposit')} />
            <Th content={t('reservation.totals.balance')} />
            <Th content={t('reservation.totals.fee')} />
            <Th content={t('reservation.totals.value')} />
            <Th content={t('reservation.totals.total')} />
          </tr>
          <tr>
            <Td content={calculateDeposit(reservation).toString()} isMoney />
            <Td content={calculateBalance(reservation).toString()} isMoney />
            <Td content={calculateFee(reservation).toString()} isMoney />
            <Td content={calculateValue(reservation).toString()} isMoney />
            <Td content={calculateTotal(reservation).toString()} isMoney />
          </tr>
        </table>
      ) : null}
    </div>
  );
}

function ReservationsTotals({
  reservations,
  showVariants = true,
  showMoney = true,
}: {
  reservations: Reservations[];
  showVariants?: boolean;
  showMoney?: boolean;
}) {
  const { t } = useTranslation();

  const allVariants = getAllVariants(reservations);

  return (
    <>
      {showVariants ? (
        <Table>
          <tr>
            {allVariants.map((variant) => (
              <Th
                key={variant.id}
                truncate={false}
                content={`${variant.service.name} ${variant.name}`}
              />
            ))}
          </tr>
          <tr>
            {allVariants.map((variant) => (
              <Td content={sumVariantById(reservations, variant.id)}></Td>
            ))}
          </tr>
        </Table>
      ) : null}

      {showMoney ? (
        <table className="text-xs text-gray-500 w-full flex-0 text-center">
          <tr>
            <Th content={t('reservation.totals.deposit')} truncate={false} />
            <Th content={t('reservation.totals.balance')} truncate={false} />
            <Th content={t('reservation.totals.fee')} truncate={false} />
            <Th content={t('reservation.totals.value')} truncate={false} />
            <Th content={t('reservation.totals.total')} truncate={false} />
          </tr>
          <tr>
            <Td content={calculateTotalDeposit(reservations)} isMoney />
            <Td content={calculateTotalBalance(reservations)} isMoney />
            <Td content={calculateTotalFee(reservations)} isMoney />
            <Td content={calculateTotalValue(reservations)} isMoney />
            <Td content={calculateTotalAmount(reservations)} isMoney />
          </tr>
        </table>
      ) : null}
    </>
  );
}
