import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Login from './pages/login';
import store from './store';
import { TooltipProvider } from '@/ui/tooltip';
import { ThemeProvider } from './theme-provider';
import Booking from './pages/booking';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import Reservations from './pages/reservations';
import Home from './pages/home';
import Reservation from './pages/reservation';
import Ticket from './pages/ticket';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Initialize i18next
i18next
  .use(Backend)
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    fallbackLng: 'it', // Default language
    lng: 'it',
    debug: true, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

// ... existing code ...
const PrivateRoutes = () => {
  const { isAuthenticated, initializing } = store();

  if (initializing) return null;
  if (isAuthenticated === true) return <Outlet />;
  return <Login />;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'book/:id',
        element: <Booking />,
      },
      {
        path: 'reservations',
        element: <Reservations />,
      },
      {
        path: 'reservations/:id',
        element: <Reservation />,
      },
      {
        path: 'ticket/:id',
        element: <Ticket />,
      },
    ],
  },
]);

export function App() {
  const { setUser, unAuthenticate, initializing } = store((s) => s);
  const queryClient = new QueryClient();

  return !initializing ? (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light" storageKey="theme">
        <TooltipProvider>
          <AnimatePresence mode="sync" initial={true}>
            <RouterProvider router={router} />
          </AnimatePresence>
        </TooltipProvider>
      </ThemeProvider>
    </QueryClientProvider>
  ) : (
    'loading...'
  );
}

export default App;
