import { LoaderIcon } from 'lucide-react';
import React from 'react';

const Loading = () => {
  return (
    <div>
      <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm p-3">
        <div className="flex flex-col items-center gap-1 text-center">
          <LoaderIcon className="h-6 w-6 animate-spin" />
          <h3 className="text-2xl font-bold tracking-tight">Loading</h3>
          <p className="text-sm text-muted-foreground">Please wait...</p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
