import { cn } from '@/lib/utils';
import { buttonVariants } from '@/ui/button';
import { ClassNames } from 'react-day-picker';

export const styles: ClassNames = {
  root: 'w-full',
  months:
    'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 text-left',
  month: 'space-y-4 w-full',
  caption: 'flex justify-center text-right pt-1 relative items-center',
  caption_label: 'text-xl  w-full',
  nav: 'space-x-1 flex items-center px-1',
  nav_button: cn(
    buttonVariants({ variant: 'outline' }),
    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border-0'
  ),
  nav_button_previous: '',
  nav_button_next: '',
  weeknumber: `text-slate-300 text-xs h-full w-full inline-block flex items-center justify-center before:content-['W'] ml-1`,
  table: 'w-full border-collapse space-y-1 table-auto table-responsive',
  head_row: 'flex',
  head_cell:
    'text-muted-foreground rounded-md font-normal text-[0.8rem] w-full text-center',
  row: 'flex w-full mt-2',
  cell: cn(
    'w-full relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
    '[&:has([aria-selected])]:rounded-md'
  ),
  day: cn(
    buttonVariants({ variant: 'ghost' }),
    'h-12 w-18 w-full p-0 font-light text-xl aria-selected:opacity-100'
  ),
  day_range_start: 'day-range-start',
  day_range_end: 'day-range-end',
  day_selected:
    'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
  day_today: 'bg-accent text-accent-foreground',
  day_outside:
    'day-outside text-muted-foreground opacity-30  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
  day_disabled: 'text-muted-foreground opacity-50',
  day_range_middle:
    'aria-selected:bg-accent aria-selected:text-accent-foreground',
  day_hidden: 'invisible',
};

export const calAnimation = (width: number) => ({
  initial: { x: -width, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -width, opacity: 0 },
  transition: { duration: 0.4 },
});

export const shiftAnimation = (width: number) => ({
  initial: { x: +width, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: +width, opacity: 0 },
  transition: { duration: 0.4 },
});
