import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { login, getUser, isAuthenticated, logout } from '@/lib/api';

type AppState = {
  initializing: boolean;
  isAuthenticated: boolean;
  setUser: (user: any) => void;
  unAuthenticate: () => void;
  login: (loginParams: { email: string; password: string }) => void;
  user: any;
};

const store = create<AppState>(
  devtools((set) => ({
    user: null,
    isAuthenticated: undefined,
    initializing: true,
    setUser: (user) =>
      set({ user, isAuthenticated: true, initializing: false }),
    unAuthenticate: async () => {
      await logout();
      set({ user: null, isAuthenticated: false, initializing: false });
    },
    login: async (loginParams: { email: string; password: string }) => {
      try {
        await login(loginParams);
        set({ user: null, isAuthenticated: true });
        const user = await getUser();
        set({ user, isAuthenticated: true });
      } catch (error) {
        set({ user: null, isAuthenticated: false, initializing: false });
      }
    },
  }))
);

isAuthenticated().then((user) => {
  if (user) {
    store.setState({
      user,
      isAuthenticated: true,
      initializing: false,
    });
  } else {
    store.setState((state) => ({
      user: null,
      isAuthenticated: state.isAuthenticated,
      initializing: false,
    }));
  }
});

export default store;
