import { ASSETS_URL } from '@/consts';
import { Service } from '@/global';
import Markdown from 'react-markdown';

interface ServiceCardProps {
  service: Service;
}
export default function ServiceCard({ service }: ServiceCardProps) {
  return (
    service && (
      <div>
        <img
          alt={service.name.toLowerCase()}
          src={ASSETS_URL + service?.cover}
          className="bg-slate-100 aspect-video w-full rounded-lg object-cover"
          onError={(e) => (e.currentTarget.src = '/clippy.svg')}
        />

        <div className="p-2">
          <h3 className="font-bold capitalize">{service.name.toLowerCase()}</h3>

          <Markdown className="text-sm/relaxed text-gray-500 prose-sm">
            {service.description}
          </Markdown>

          <p className="text-sm/relaxed text-gray-500">{service.contract}</p>
        </div>
      </div>
    )
  );
}
