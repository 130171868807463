import { Link, useParams } from 'react-router-dom';
import { getReservation, reservationVoid } from '@/lib/api';
import { useApiGet, useApiSend } from '@/lib/api/client';
import Layout from './layout';
import Money from '@/ui/money';
import Loading from '@/components/loading';
import { Switch } from '@/ui/switch';
import { Label } from '@/ui/label';
import { ElementType, useState } from 'react';
import { Button } from '@/ui/button';
import { TicketIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Reservations } from '@/global';
import {
  formatDate,
  formatDateTime,
  getPropertyValueOrReference,
} from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { Heading } from '@/ui/heading';
import { StatusBadge } from '@/ui/status-badge';
import {
  calculateBalance,
  calculateDeposit,
  calculateFee,
  calculateTotal,
  calculateValue,
  isRecentSellerReservation,
  sortedVariantsByReservation,
} from '@/lib/reservation';
import {
  BanknoteIcon,
  Coins,
  Euro,
  Landmark,
  PiggyBankIcon,
} from 'lucide-react';
import { Badge } from '@/ui/badge';
import store from '../store';

function Reservation() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = store();
  const u = user?.data;

  const [showCosts, setShowCosts] = useState(false);

  const {
    data: reservationData,
    isLoading: isLoadingGetReservation,
    isFetching: isFetchingGetReservation,
    refetch,
  } = useApiGet([], async () => await getReservation({ id }), {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { isPending: isVoidPending, mutate: voidMutation } = useApiSend(
    (data: Reservations) => {
      return reservationVoid(data.id);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const isLoading =
    isLoadingGetReservation || isFetchingGetReservation || isVoidPending;

  if (!reservationData) {
    return;
  }

  return (
    <Layout
      side={
        !isLoading && (
          <div className="w-full space-y-3 p-4 flex flex-col text-left">
            <Button asChild variant="outline" size="lg">
              <Link to={`/ticket/${id}`} target="_blank">
                <TicketIcon className="size-4 mr-2" />
                {t('reservation.print-ticket')}
              </Link>
            </Button>

            {reservationData?.status === 'true' && (
              <Button
                variant="destructive"
                disabled={isVoidPending}
                size="lg"
                onClick={() => {
                  voidMutation(reservationData);
                }}
              >
                <XMarkIcon className="size-4 mr-2" />
                {t('reservation.void')}
              </Button>
            )}
          </div>
        )
      }
    >
      {!isLoading ? (
        <>
          <Heading
            className="p-4"
            title={
              <span className="capitalize">
                {reservationData?.customer_name}
              </span>
            }
            description={`${reservationData?.customer_phone} - ${reservationData?.customer_email}`}
          ></Heading>

          <div className="space-y-4 w-full p-4">
            <dl className="divide-y divide-gray-100">
              <Row
                title={t('reservation.service.title')}
                value={reservationData.service.name}
              />
              <Row
                title={t('reservation.booked-date.title')}
                value={formatDate(reservationData.booked_date)}
              />
              <Row
                title={t('reservation.shift.title')}
                value={`${reservationData.shift.name}: ${reservationData.shift.from} → ${reservationData.shift.to}`}
              />
              <Row
                title={t('reservation.status.title')}
                value={
                  reservationData.status === 'true' ? (
                    <StatusBadge
                      color="emerald"
                      label={t('common.reservation.active')}
                    />
                  ) : (
                    <StatusBadge
                      color="red"
                      label={t('common.reservation.void')}
                    />
                  )
                }
              />
              <Row
                title={t('reservation.variants.title')}
                value={
                  <ul className="w-full divide-y divide-gray-100 rounded-md border border-gray-200">
                    {sortedVariantsByReservation(reservationData)?.map(
                      (variant) => (
                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                          <div className="flex w-0 flex-1 items-center">
                            <TicketIcon className="size-5 shrink-0 text-gray-400" />
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              {variant.amount} &times;{' '}
                              {getPropertyValueOrReference(
                                variant.variant,
                                'name'
                              )}
                            </div>
                          </div>
                          <div className="ml-4 flex shrink-0 space-x-4">
                            <Money amount={variant.total} />
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                }
              />
              <Row
                title={<>{t('reservation.totals.title')} </>}
                value={
                  <div className="w-full space-y-4">
                    <ul className="w-full divide-y divide-gray-100 rounded-md border border-gray-200">
                      <TotalRow
                        title={
                          <>
                            {t('reservation.totals.deposit')}{' '}
                            {isRecentSellerReservation(
                              reservationData,
                              u.id
                            ) ? (
                              <Badge
                                variant="outline"
                                className="animate-pulse text-orange-400"
                              >
                                {t('reservation.totals.recall-to-take-deposit')}
                              </Badge>
                            ) : null}
                          </>
                        }
                        value={calculateDeposit(reservationData)}
                        icon={Coins}
                      />
                      <TotalRow
                        title={t('reservation.totals.balance')}
                        value={calculateBalance(reservationData)}
                        icon={BanknoteIcon}
                      />
                    </ul>
                    <Label className="flex items-center gap-2">
                      <Switch
                        checked={showCosts}
                        onCheckedChange={setShowCosts}
                      />
                      {t('reservation.totals.show-fees')}
                    </Label>
                    {showCosts && (
                      <ul className="w-full divide-y divide-gray-100 rounded-md border border-gray-200">
                        <TotalRow
                          title={t('reservation.totals.fee')}
                          value={calculateFee(reservationData)}
                          icon={PiggyBankIcon}
                        />
                        <TotalRow
                          title={t('reservation.totals.value')}
                          value={calculateValue(reservationData)}
                          icon={Landmark}
                        />
                      </ul>
                    )}
                    <ul className="w-full divide-y divide-gray-100 rounded-md border border-gray-200">
                      <TotalRow
                        title={t('reservation.totals.total')}
                        value={calculateTotal(reservationData)}
                        icon={Euro}
                      />
                    </ul>
                  </div>
                }
              />
              <Row
                title={t('reservation.seller.title')}
                value={
                  <div className="flex flex-col gap-1">
                    <div>
                      {reservationData.seller.first_name}{' '}
                      {reservationData.seller.last_name} •{' '}
                      {reservationData.seller.company}
                    </div>
                    <div className="text-gray-500 text-xs">
                      {formatDateTime(reservationData.date_created)}
                    </div>
                  </div>
                }
              />
            </dl>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Layout>
  );
}
export default Reservation;

function Row({
  title,
  value,
}: {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
}) {
  return (
    <div className="py-2 sm:grid lg:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-gray-900">{title}</dt>
      <dd className="mt-1 flex text-sm/6 text-gray-700 sm:mt-0 col-span-2">
        {value}
      </dd>
    </div>
  );
}

function TotalRow({
  title,
  value,
  icon,
}: {
  title: string | React.ReactNode;
  value: number;
  icon: ElementType;
}) {
  const Icon = icon;

  return (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
      <div className="flex w-0 flex-1 items-center">
        <Icon className="size-5 shrink-0 text-gray-400" />
        <div className="ml-4 flex min-w-0 flex-1 gap-2">{title}</div>
      </div>
      <div className="ml-4 flex shrink-0 space-x-4">
        <Money amount={value} />
      </div>
    </li>
  );
}
