import { Filters } from '@/app/pages/reservations';
import { Service } from '@/global';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/ui/accordion';
import { FilterIcon } from 'lucide-react';
import { Button } from '@/ui/button';
import Truncate from 'react-truncate-inside/es';

export function PresetList({
  services,
  applyPreset,
  selectedPreset,
  setSelectedPreset,
}: {
  services: Service[];
  applyPreset: (preset: Preset) => void;
  selectedPreset: {
    parent: string;
    preset: string;
  } | null;
  setSelectedPreset: (preset: { parent: string; preset: string }) => void;
}) {
  const presets = generatePresets(services);
  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue={selectedPreset?.parent || 'general'}
    >
      {Object.entries(presets).map(([key, preset]) => (
        <AccordionItem value={key} key={key} className="border-0">
          <AccordionTrigger className="capitalize text font-semibold text-slate-900/80">
            {preset.name.toLocaleLowerCase()}
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col space-y-2">
              {Object.entries(preset.entries).map(([presetKey, preset]) => (
                <Button
                  variant={'ghost'}
                  key={presetKey}
                  className={
                    selectedPreset?.preset === presetKey
                      ? 'bg-slate-900 text-slate-50'
                      : ''
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPreset({
                      parent: key,
                      preset: presetKey,
                    });
                    applyPreset(preset);
                  }}
                >
                  <FilterIcon className="size-4 mr-1" />
                  <div className="w-full text-left capitalize">
                    <Truncate
                      width={250}
                      offset={3}
                      text={preset.name.toLocaleLowerCase()}
                    />
                  </div>
                </Button>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export type Preset = {
  name: string;
  filters: Filters;
  columns: {
    variants: boolean;
    money: boolean;
  };
};

export type PresetList = Record<
  string,
  {
    name: string;
    entries: Record<string, Preset>;
  }
>;

function generatePresets(services: Service[]): PresetList {
  return {
    general: {
      name: 'General',
      entries: {
        onBoard: {
          name: 'Partono oggi',
          filters: {
            customer: '',
            ticket: '',
            service: 'all',
            bookingDateRange: undefined,
            bookedDateRange: {
              from: new Date(),
              to: new Date(),
            },
            status: undefined,
            shift: 'all',
          },
          columns: {
            variants: true,
            money: false,
          },
        },
        soldToday: {
          name: 'Venduti oggi',
          filters: {
            customer: '',
            ticket: '',
            service: 'all',
            bookedDateRange: undefined,
            bookingDateRange: {
              from: new Date(),
              to: new Date(),
            },
            status: undefined,
            shift: 'all',
          },
          columns: {
            variants: false,
            money: true,
          },
        },
      },
    },
    ...services?.reduce(
      (acc, service) => ({
        ...acc,
        [service.id]: {
          name: service.name,
          entries: {
            ...service.shifts?.reduce(
              (shiftAcc, shift) => ({
                ...shiftAcc,
                [shift.id + '-sold-today']: {
                  name: `${shift.name} - venduti oggi`,
                  filters: {
                    customer: '',
                    ticket: '',
                    service: service.id,
                    shift: shift.id,
                    bookedDateRange: undefined,
                    bookingDateRange: {
                      from: new Date(),
                      to: new Date(),
                    },
                  },
                },
                [shift.id + '-out-today']: {
                  name: `${shift.name} - partono oggi`,
                  filters: {
                    customer: '',
                    ticket: '',
                    service: service.id,
                    shift: shift.id,
                    bookedDateRange: {
                      from: new Date(),
                      to: new Date(),
                    },
                    bookingDateRange: undefined,
                  },
                },
              }),
              {}
            ),
          },
        },
      }),
      {}
    ),
  };
}
