import store from '@/app/store';
import { BASE_URL } from '@/consts';
import {
  MutationFunction,
  QueryKey,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axios from 'axios';

export function initClient() {
  const baseInstance = axios.create({ baseURL: BASE_URL });
  const axiosInstance = axios.create({ baseURL: BASE_URL });

  function refreshAccessToken() {
    if (!localStorage.getItem('refreshToken')) {
      return;
    }
    return axios
      .post(`${BASE_URL}/auth/refresh`, {
        refresh_token: localStorage.getItem('refreshToken'),
        mode: 'json',
      })
      .then((response) => {
        localStorage.setItem('accessToken', response.data.data.access_token);
        localStorage.setItem('refreshToken', response.data.data.refresh_token);
        localStorage.setItem('timeout', response.data.data.expires);
        return response.data.access_token;
      });
  }

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (config.url === '/auth/login' || config.url === '/auth/refresh') {
        return config;
      }

      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        Promise.reject(error);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        if (access_token) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + access_token;
        }
        originalRequest._retry = false;
        return axiosInstance(originalRequest);
      }
      if (error.response.status === 401 && originalRequest._retry) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('timeout');
        store.setState((s) => ({
          ...s,
          isAuthenticated: false,
          user: undefined,
        }));
      }

      return Promise.reject(error);
    }
  );

  return { authClient: axiosInstance, client: baseInstance };
}

export function useApiSend<Response, Payload>(
  fn: MutationFunction<Response, Payload>,
  options: Record<string, unknown>
) {
  return useMutation({
    mutationFn: fn,
    ...options,
  });
}

export const useApiGet = <D>(
  key: QueryKey,
  fn: (any?: any) => Promise<D>,
  options: Record<string, unknown>
): UseQueryResult<D> =>
  useQuery({
    queryKey: key ?? undefined,
    queryFn: fn,
    ...options,
  });
