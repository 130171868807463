import { useTranslation } from 'react-i18next';

export function NotAvailableStamp() {
  const { t } = useTranslation();
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-12 border-2 border-slate-700 rounded-lg px-4 py-2 text-center text-slate-700 font-bold">
      {t('common.notAvailable')}
    </div>
  );
}
