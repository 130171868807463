import { getReservations } from '@/lib/api';
import { useApiGet } from '@/lib/api/client';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading } from '@/ui/heading';
import { SkeletonLoader } from '@/ui/skeleton-loader';
import { ErrorAlert } from '@/ui/error-alert';
import { NoDataAlert } from '@/ui/no-data-alert';
import { StatusBadge } from '@/ui/status-badge';
import { formatDate } from '@/lib/utils';
import { Reservations } from '@/global';
import { Circle } from '@/ui/divider';

export default function LastReservations() {
  const { t } = useTranslation();
  const { data, isLoading, isError, isLoadingError, isFetching, refetch } =
    useApiGet(
      ['last-reservations'],
      async () => await getReservations({ limit: 6 }),
      {
        enabled: true,
        refetchOnWindowFocus: true,
        retry: 1,
      }
    );

  return (
    <div className="hidden md:block">
      <Heading
        className="p-4"
        title={t('components.last-reservations.title')}
        description={t('components.last-reservations.description')}
      />

      {(isLoading || isFetching) && <SkeletonLoader className="p-4" />}

      {(isError || isLoadingError) && (
        <ErrorAlert
          onRetry={refetch}
          title={t('common.errors.unexpected.description')}
          hideDescription
        />
      )}

      {!data?.length &&
        !isLoading &&
        !isFetching &&
        !isError &&
        !isLoadingError && <NoDataAlert />}

      <ul>
        {data?.map((reservation) => (
          <Link to={`/reservations/${reservation.id}`} key={reservation.id}>
            <ReservationListItem reservation={reservation} />
          </Link>
        ))}
      </ul>
    </div>
  );
}

const ReservationListItem = ({
  reservation,
}: {
  reservation: Reservations;
}) => {
  const { t } = useTranslation();
  return (
    <li className="relative flex justify-between gap-x-6 p-4 hover:bg-gray-50">
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto">
          <p className="text-sm/5 font-bold text-gray-900">
            {reservation.service.name}
          </p>
          <p className="text-sm/6 text-gray-900 capitalize">
            {reservation.customer_name.toLowerCase()}
          </p>
          <p className="text-xs/5 text-gray-500 flex items-center gap-x-2">
            {formatDate(reservation.booked_date)} <Circle />{' '}
            {reservation.shift.name}
          </p>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="sm:flex sm:flex-col sm:items-end">
          {reservation.status === 'true' && (
            <StatusBadge
              color="emerald"
              label={t('common.reservation.active')}
            />
          )}
          {reservation.status === 'false' && (
            <StatusBadge color="red" label={t('common.reservation.void')} />
          )}
        </div>
        <svg
          className="size-5 flex-none text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </li>
  );
};
