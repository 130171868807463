import { useParams } from 'react-router-dom';
import ServiceCard from '@/components/booking/service-card';
import BookingForm from '@/components/booking/booking-form';
import { getSellableService } from '@/lib/api';
import { useApiGet } from '@/lib/api/client';
import ErrorScreen from '@/components/error-screen';
import Layout from './layout';
import { SkeletonLoader } from '@/ui/skeleton-loader';
import { Heading } from '@/ui/heading';
import { Service } from '@/global';
import { useTranslation } from 'react-i18next';

function Booking() {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: service,
    isLoading,
    error,
    isError,
    isLoadingError,
    isFetching,
  } = useApiGet<Service>([], async () => await getSellableService(id), {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  if ((!service && (!isLoading || !isFetching)) || isError || isLoadingError) {
    return (
      <ErrorScreen
        message={error?.message ?? t('common.errors.unexpected.description')}
      />
    );
  }

  return (
    <Layout
      side={
        <div className="p-4 w-full">
          {isLoading || isFetching ? (
            <SkeletonLoader className="p-3" />
          ) : (
            service && <ServiceCard service={service} />
          )}
        </div>
      }
    >
      <div className="w-full">
        {isLoading || isFetching ? (
          <SkeletonLoader className="p-4" />
        ) : (
          service && (
            <>
              <Heading
                className="p-4"
                title={
                  service.name?.charAt(0).toUpperCase() +
                  service.name?.slice(1).toLowerCase()
                }
                description={service.subtitle}
              />
              <BookingForm service={service} />
            </>
          )
        )}
      </div>
    </Layout>
  );
}
export default Booking;
